export const API_URL = process.env.VUE_APP_PATH_START;

export const ASSISTANT_NAME = 'ТUЛЛUК';

import azbuka from '@/assets/img/promo/azbuka.jpg';
import ppp from '@/assets/img/promo/ppp.jpg';
import zvezda from '@/assets/img/promo/zvezda.jpg';
import kamelek from '@/assets/img/promo/kamelek.jpg';

export const PROMO_DATA = [
  {
    id: 0,
    channelId: 405,
    link: '',
    image: azbuka,
    description: 'Азбука',
  },
  {
    id: 1,
    channelId: 1016,
    link: '',
    image: ppp,
    description: 'ПрофПланПроект',
  },
  {
    id: 2,
    channelId: 1035,
    link: '',
    image: zvezda,
    description: 'Звезда Якутии',
  },
  {
    id: 3,
    channelId: 32,
    link: '',
    image: kamelek,
    description: 'Камелёк',
  },
];
export const FEED_PROMO_DATA = [];

export const KZ_MOB_OPERATOR = [
  '700',
  '701',
  '702',
  '703',
  '704',
  '705',
  '706',
  '707',
  '708',
  '709',
  '747',
  '750',
  '751',
  '760',
  '761',
  '762',
  '763',
  '764',
  '771',
  '775',
  '776',
  '777',
  '778',
];